/* eslint-disable complexity */
import React from "react";
import PropType from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import ChatIcon from "@mui/icons-material/Chat";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import SettingsCellIcon from "@mui/icons-material/SettingsCell";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import SpeedIcon from "@mui/icons-material/Speed";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import {allowArrhythmiaSettings} from "../pages/ArrhythmiaSettingsPage/ArrhythmiaSettingsPage.jsx";
import {allowBulkDeviceActions} from "../pages/BulkDeviceActions/BulkDeviceActions.jsx";
import {allowDashboard} from "../pages/Dashboard/Dashboard.jsx";
import {allowDeviceConfigurations} from "../pages/DeviceConfigurationsPage/DeviceConfigurationsPage.jsx";
import {allowDevices} from "../pages/DevicesPage/DevicesPage.jsx";
import {allowDeviceTypes} from "../pages/DeviceTypesPage/DeviceTypesPage.jsx";
import {allowDeviceVariants} from "../pages/DeviceVariantsPage/DeviceVariantsPage.jsx";
import {allowFirmwareReleases} from "../pages/FirmwareReleasesPage/FirmwareReleasesPage.jsx";
import {allowInsuranceTypes} from "../pages/InsuranceTypesPage/InsuranceTypesPage.jsx";
import {allowMessageOptions} from "../pages/MessageOptionsPage/MessageOptionsPage.jsx";
import {allowServiceBilling} from "../pages/ServiceBillingPage/ServiceBillingPage.jsx";
import {allowStripClassifications} from "../pages/StripClassificationsPage/StripClassificationsPage.jsx";
import {allowStudyAccessoryKits} from "../pages/StudyAccessoryKitsPage/StudyAccessoryKitsPage.jsx";
import {allowStudyConfigurations} from "../pages/StudyConfigurationsPage/StudyConfigurationsPage.jsx";
import {allowSupport} from "../pages/SupportPage/SupportPage.jsx";
import {allowTerminateDevices} from "../pages/TerminateDevicesPage/TerminateDevicesPage.jsx";
import {allowTerminated} from "../pages/TerminatedPage/TerminatedPage.jsx";
import {allowUsageReport} from "../pages/UsageReportPage/UsageReportPage.jsx";
import {allowUsers} from "../pages/UsersPage/UsersPage.jsx";
import {allowWorkflows} from "../pages/WorkflowsPage/WorkflowsPage.jsx";
import useJwt from "./contexts/useJwt.jsx";
import useLogout from "./hooks/useLogout.jsx";
import NavButton from "./primitives/NavButton.jsx";

function SideBar({
  // Props
  onClick,
}) {
  const logout = useLogout();
  const handleLogout = React.useCallback(
    (event) => {
      // Without this preventDefault, the redirect in Layout.jsx fires and overwrites the message
      event.preventDefault();
      logout();
    },
    [logout]
  );

  const {inboxAccess, isInAnyRole, facilityDisabled, userFacilityBilling} = useJwt();

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <List onClick={onClick}>
      {allowDashboard(isInAnyRole) && (
        <>
          <ListSubheader sx={{color: "theme.palette.text.main"}}>
            <Typography variant="overline">Admin</Typography>
          </ListSubheader>
          <NavButton Icon={<SpeedIcon fontSize="small" />} text="Dashboard" to="/dashboard" />
          <Divider sx={{my: 1}} light />
        </>
      )}

      <ListSubheader sx={{color: "theme.palette.text.main"}}>
        <Typography variant="overline">Device Operations</Typography>
      </ListSubheader>
      {allowDevices(isInAnyRole) && (
        <NavButton
          Icon={<SmartphoneIcon fontSize="small" />}
          text="Devices"
          to="/devices"
          data-cy="devices-menu-button"
        />
      )}
      {allowTerminated(isInAnyRole) && (
        <NavButton Icon={<MobileOffIcon fontSize="small" />} text="Terminated" to="/terminated" />
      )}
      {allowDeviceTypes(isInAnyRole) && (
        <NavButton Icon={<DeveloperModeIcon fontSize="small" />} text="Device Types" to="/device-types" />
      )}
      {allowDeviceVariants(isInAnyRole) && (
        <NavButton
          Icon={<PhonelinkSetupIcon fontSize="small" />}
          text="Device Variants"
          to="/device-variants"
          data-cy="device-variants-menu-button"
        />
      )}
      {allowFirmwareReleases(isInAnyRole) && (
        <NavButton
          Icon={<InsertDriveFileOutlinedIcon fontSize="small" />}
          text="Firmware Releases"
          to="/firmware-releases"
        />
      )}
      {allowBulkDeviceActions(isInAnyRole, facilityDisabled) && (
        <NavButton
          Icon={<AddToHomeScreenIcon fontSize="small" />}
          text="Bulk Device Actions"
          to="/bulk-device-actions"
          data-cy="bulk-device-actions-menu-button"
        />
      )}
      {allowTerminateDevices(isInAnyRole) && (
        <NavButton
          Icon={<PhonelinkEraseIcon fontSize="small" />}
          text="Terminate Devices"
          to="/terminate-devices"
          data-cy="terminate-devices-menu-button"
        />
      )}
      <Divider sx={{my: 1}} light />
      <ListSubheader sx={{color: "theme.palette.text.main"}}>
        <Typography variant="overline">Account Management</Typography>
      </ListSubheader>
      <NavButton
        Icon={<BusinessIcon fontSize="small" />}
        text="Facilities"
        to="/facilities"
        data-cy="facilities-menu-button"
      />
      {allowUsers(isInAnyRole) && (
        <NavButton
          Icon={<PeopleIcon fontSize="small" />}
          text="Users"
          to="/users"
          data-cy="users-menu-button"
        />
      )}
      {allowStudyAccessoryKits(isInAnyRole, userFacilityBilling) && (
        <NavButton
          Icon={<ElectricalServicesIcon fontSize="small" />}
          text="Study Accessory Kits"
          to="/study-accessory-kits"
          data-cy="study-accessory-kits-menu-button"
        />
      )}
      {allowUsageReport(isInAnyRole) && (
        <NavButton Icon={<BarChartIcon fontSize="small" />} text="Usage Report" to="/usage-report" />
      )}
      {allowServiceBilling(isInAnyRole, userFacilityBilling, inboxAccess) && (
        <NavButton
          Icon={<PaymentsIcon fontSize="small" />}
          text="Service Billing"
          to="/service-billing"
          data-cy="service-billing-menu-button"
        />
      )}

      {isInAnyRole(["tzAdmin", "facilityAdmin"]) && inboxAccess && (
        <>
          <Divider sx={{my: 1}} light />
          <ListSubheader sx={{color: "theme.palette.text.main"}}>
            <Typography variant="overline">Inbox Settings</Typography>
          </ListSubheader>
        </>
      )}
      {allowArrhythmiaSettings(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<MonitorHeartIcon fontSize="small" />}
          text="Arrhythmia Settings"
          to="/arrhythmia-settings"
          data-cy="arrhythmia-settings-menu-button"
        />
      )}
      {allowDeviceConfigurations(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<SettingsCellIcon fontSize="small" />}
          text="Device Configurations"
          to="/device-configurations"
          data-cy="device-configurations-menu-button"
        />
      )}
      {allowStripClassifications(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<MailOutlineIcon fontSize="small" />}
          text="Strip Classifications"
          to="/strip-classifications"
          data-cy="strip-classifications-menu-button"
        />
      )}
      {allowInsuranceTypes(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<HealthAndSafetyIcon fontSize="small" />}
          text="Insurance Types"
          to="/insurance-types"
          data-cy="insurance-types-menu-button"
        />
      )}
      {allowMessageOptions(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<ChatIcon fontSize="small" />}
          text="Message Options"
          to="/message-options"
          data-cy="message-options-menu-button"
        />
      )}
      {allowStudyConfigurations(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<AssignmentIndIcon fontSize="small" />}
          text="Study Configurations"
          to="/study-configurations"
          data-cy="study-configurations-menu-button"
        />
      )}
      {allowWorkflows(isInAnyRole, inboxAccess) && (
        <NavButton
          Icon={<DeviceHubIcon fontSize="small" />}
          text="Workflows"
          to="/workflows"
          data-cy="workflows-menu-button"
        />
      )}

      <Divider sx={{my: 1}} light />

      <ListSubheader sx={{color: "theme.palette.text.main"}}>
        <Typography variant="overline">Additional Information</Typography>
      </ListSubheader>
      {allowSupport(isInAnyRole) && (
        <NavButton Icon={<ContactSupportIcon fontSize="small" />} text="Support" to="/support" />
      )}

      <Divider sx={{my: 1}} light />

      <NavButton Icon={<LogoutIcon fontSize="small" />} onClick={handleLogout} text="SIGN OUT" />
    </List>
  );
}

SideBar.propTypes = {
  onClick: PropType.func.isRequired,
};

export default React.memo(SideBar);
