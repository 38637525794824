/* eslint-env browser */
import React from "react";

function useStudyTypeNames(variant = "normal") {
  return React.useMemo(() => {
    const displayableStudyTypes = {
      holter: "Holter",
      extendedHolter: "Extended Holter",
      wirelessHolter: "Wireless Holter",
      wirelessExtendedHolter: "Wireless Extended Holter",
      mct: "Mobile Cardiac Telemetry (MCT)",
      cem: "Cardiac Event Monitoring (CEM)",
      mctWithFullDisclosure: "MCT with Full Disclosure",
      cardiacRehab: "Cardiac Rehab",
    };

    if (variant === "complex") {
      return {
        ...displayableStudyTypes,
        mct: {
          short: "MCT",
          long: "Mobile Cardiac Telemetry (MCT)",
        },
        cem: {
          short: "CEM",
          long: "Cardiac Event Monitoring (CEM)",
        },
        mctWithFullDisclosure: {
          short: "MCT (Full Disclosure)",
          long: "MCT with Full Disclosure",
        },
      };
    }

    return displayableStudyTypes;
  }, [variant]);
}

export default useStudyTypeNames;
